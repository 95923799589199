:root {
  --main: #f17f14;
  --dark_main: #8c4a07;
  --dark: #161616;
  --grey: #3a3a3a;
  --dark_grey: #2a2a2a;
  --light_grey: #5a5a5a;
  --light: #f5f5f5;
  --red: #cc2211;
  --green: #22cc11;
  --accessibility: #2a2a2a;
  --accessibility2: #161616;
  --accessibility3: #3a3a3a;
}

::-moz-selection {
  background: var(--main);
  color: var(--light);
}

::selection {
  background: var(--main);
  color: var(--light);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dark);
}

img {
  user-select: none;
  pointer-events: none;
}

.navbar {
  position: fixed;
  display: grid;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.navbar::-webkit-scrollbar {
  display: none;
}

.navbar::-webkit-scrollbar-track {
  background-color: var(--dark);
}

.navbar button {
  margin: 9px;
  background-color: var(--dark_grey);
  border: 2px solid var(--accessibility);
  outline: none;
  border-radius: 9px;
  font-size: 1.2em;
  cursor: pointer;
  color: var(--light_grey);
}

.nav-button {
  position: relative;
}

.nav-button i,
.nav-button label {
  margin-right: 5px;
}

.nav-button label {
  font-weight: bold;
}

.navbar button:hover {
  background-color: var(--accessibility3);
}

.nav-button-active {
  background-color: var(--dark_main) !important;
  color: var(--main) !important;
}

.logo {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.logo h1 {
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  color: var(--main);
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  text-shadow: 0 0 10px var(--dark);
}

.logo img {
  width: 100%;
  margin: 0 auto;
  height: auto;
}

.container {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: url('./images/squares.png');
  background-color: var(--dark);
  background-blend-mode: multiply;
  background-size: 200px 200px;
  background-repeat: repeat;
  box-shadow: inset 0 0 20px var(--dark);
  border: 2px solid var(--accessibility2);
}

.container::-webkit-scrollbar {
  display: none;
}

.normalBG {
  background: none !important;
  background-color: var(--dark_grey) !important;
}

.container h1 {
  font-size: 2em;
  margin: 40px auto;
  padding: 0;
  color: var(--light);
  text-align: center;
  font-weight: 100;
  text-shadow: 0 0 10px var(--dark);
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {

  .logo img {
    max-width: 40px;
  }

  .logo h1 {
    display: none;
  }

  .navbar {
    width: 60px;
  }

  .nav-button {
    height: 40px;
    width: 40px;
  }

  .nav-button i,
  .nav-button label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .nav-button span {
    display: none;
  }

  .container {
    height: calc(100vh - 24px);
    width: calc(100% - 74px);
    border-radius: 25px;
    margin: 10px;
    margin-left: 60px;
  }

  .control-container h1 {
    font-size: 1.2em !important;
  }

}

@media screen and (min-width: 768px) {

  .logo img {
    max-width: 80px;
  }

  .navbar {
    width: 300px;
  }

  .nav-button i,
  .nav-button label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  @keyframes swing {
    0% {
      transform: translateY(-50%) rotate(0deg);
    }

    10% {
      transform: translateY(-50%) rotate(10deg);
    }

    30% {
      transform: translateY(-50%) rotate(-10deg);
    }

    50% {
      transform: translateY(-50%) rotate(5deg);
    }

    70% {
      transform: translateY(-50%) rotate(-5deg);
    }

    90% {
      transform: translateY(-50%) rotate(2deg);
    }

    100% {
      transform: translateY(-50%) rotate(0deg);
    }
  }

  .nav-button:hover i,
  .nav-button:hover label {
    animation: swing .7s ease infinite;
  }

  .container {
    height: calc(100vh - 104px);
    width: calc(100% - 404px);
    border-radius: 50px;
    margin: 50px;
    margin-left: 350px;
  }

}

.settings {
  background-color: var(--dark) !important;
  border: 4px solid var(--dark_grey) !important;
}

.settings:hover {
  border: 4px solid var(--dark_main) !important;
  color: var(--dark_main) !important;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image-container2 {
  display: none;
}

.control-container {
  position: absolute;
  top: 30px;
  left: 40px;
  width: calc(100% - 100px);
  height: fit-content;
  padding: 10px;
  background-color: rgba(0, 0, 0, .2);
  backdrop-filter: blur(10px);
  border-radius: 25px;
  z-index: 1;
}

.control-container h1 {
  margin: 0 auto;
  margin-top: 20px;
  padding: 0;
  color: var(--light);
  text-align: center;
  font-weight: 100;
  text-shadow: 0 0 10px var(--dark);
}

.container h3 {
  margin: 0 auto;
  padding: 0;
  color: var(--dark);
  text-align: center;
  font-weight: 100;
  transform: translateY(-30px);
}

.classic_text {
  text-transform: none !important;
}

.upload {
  position: relative;
}

.upload input[type="file"] {
  display: none;
}

.upload label {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  border-radius: 10px;
  width: calc(90% - 40px);
  max-width: 300px;
  color: var(--grey);
  font-size: 1.2em;
  cursor: pointer;
  text-align: center;
  border: 4px dashed var(--grey);
}

.upload label:hover {
  border-color: var(--light_grey);
  color: var(--light_grey);
}

.upload button {
  background-color: var(--red) !important;
  border: 4px solid var(--red) !important;
}

.control-container .special {
  background-color: var(--main) !important;
  border: 4px solid var(--main) !important;
}

.control-container button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  border-radius: 10px;
  width: calc(90% - 40px);
  max-width: 300px;
  color: var(--light);
  font-size: 1.2em;
  cursor: pointer;
  text-align: center;
  background-color: var(--green);
  border: 4px solid var(--green);
  transition: all .3s ease;
}

.loading {
  position: relative;
  height: 70px !important;
}

.loading img {
  height: 70px !important;
  width: auto;
}

.control-container button:hover {
  filter: brightness(.9);
  opacity: .9;
}

.control-container button i {
  margin-right: 5px;
}

.control-container button:active,
.control-container button:focus,
.control-container button:target {
  outline: none;
  transform: scale(.9);
  filter: brightness(.6);
}

.control-container input[type="range"] {
  display: block;
  margin: 20px auto;
  width: 90%;
  max-width: 300px;
  cursor: pointer;
  filter: grayscale(1) contrast(5) brightness(0) invert(1);
}

.home-container {
  padding: 0 10px;
  width: calc(100% - 20px);
}

.home h2 {
  font-size: 1.4em;
  margin: 20px auto;
  padding: 0;
  color: var(--dark_main);
  text-align: left;
  font-weight: 100;
  text-shadow: 0 0 10px var(--dark);
  margin-top: 60px;
}

.home h2:first-child {
  margin-top: 20px !important;
}

.home p {
  font-size: 1.2em;
  margin: 20px auto;
  padding: 0;
  color: var(--light_grey);
  text-align: left;
  font-weight: 100;
  line-height: 200%;
}

.home p a {
  color: var(--light_grey);
  cursor: pointer;
  font-weight: 100;
  text-decoration: underline;
}

.home p a:hover {
  color: var(--dark_main);
}

.settings-item {
  display: flex;
  width: 300px;
  margin: 20px;
  padding-left: 20px;
  align-items: center;
}

.settings-item select {
  background-color: var(--dark) !important;
  border: 4px solid var(--dark) !important;
  color: var(--light) !important;
  padding: 0;
  border-radius: 10px !important;
  cursor: pointer;
  width: 95px;
  height: 40px;
  text-align: center;
  margin-left: 95px;
}

.settings-item p {
  position: absolute;
  left: 20px;
  font-size: 25px;
  margin: 20px auto;
  padding: 0;
  color: var(--light);
  font-weight: 100;
  line-height: 200%;
  transform: translateY(-1px);
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  margin-left: 145px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--main);
}

input:focus+.slider {
  box-shadow: 0 0 1px var(--main);
}

input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.download:disabled {
  background-color: var(--grey) !important;
  border: 4px solid var(--grey) !important;
  cursor: not-allowed;
}